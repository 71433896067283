import { get, API_URL } from '@/api/axios';
import { Getters, Module, Mutations } from 'vuex-smart-module'
import { MarketItem } from './marketState';

export interface IOrderResult {
    message: "success" | "page_cart.order.not_enough" | "page_market.order_exist";
    accepted: boolean;
}
export type DeliveryStatus = "created" | "in_progress" | "failed" | "completed";
export interface IOrder {
    _id: string;
    items: MarketItem[];
    deliveryStatus: DeliveryStatus;
    user_ref: string;
    isCurrent: boolean;
}
export class State {
    public error: string | undefined;
}

export class StateMutation extends Mutations<State> {}
export class StateGetters extends Getters<State> {
    async get_orders(): Promise<IOrder[]> {
        return (await get<IOrder[]>(`${API_URL}/api/@market/order`)).data!;
    }
}


export const orderModule = new Module({
    state: State,
    getters: StateGetters,
    mutations: StateMutation
});