import Vue from "vue";
import VueRouter from "vue-router";
import AppToday from "../AppToday.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "login",
    component: () => import("../views/Login.vue")
  },
  {
    path: "/register",
    name: "register",
    component: () => import("../views/Register.vue")
  },
  {
    path: "/",
    component: AppToday,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: "home", // ???
        name: "home",
        component: () => import("../views/Home.vue")
      },
      {
        path: "settings",
        name: "settings",
        component: () =>
          import(/* webpackChunkName: "settings" */ "../views/Settings.vue")
      },
      {
        path: "dashboard",
        name: "messages",
        component: () =>
          import(/* webpackChunkName: "messages" */ "../views/Messages.vue"), // TODO make real dashboard!!!
        meta: {
          guest: true,
          requiresAuth: true
        }
      },
      {
        path: "about",
        name: "about",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/About.vue"),
        meta: {
          guest: false,
          requiresAuth: true
        }
      },
      {
        path: "kb",
        name: "kb",
        component: () => import(/* webpackChunkName: "kb" */ "../views/Kb.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "employees",
        name: "employees",
        component: () =>
          import(/* webpackChunkName: "employees" */ "../views/Employees.vue"),
        meta: {
          guest: true,
          requiresAuth: true
        }
      },
      {
        path: "employee/:id",
        name: "employee",
        component: () =>
          import(/* webpackChunkName: "employee" */ "../views/Employee.vue"),
        meta: {
          guest: true,
          requiresAuth: true
        }
      },
      {
        path: "document/:id",
        name: "document",
        component: () =>
          import(
            /* webpackChunkName: "document" */ "../views/DocumentView.vue"
          ),
        meta: {
          guest: true,
          requiresAuth: true
        }
      },
      {
        path: "money",
        name: "money",
        component: () =>
          import(/* webpackChunkName: "money" */ "../views/Money.vue"),
        meta: {
          requiresAuth: true,
          is_admin: true
        }
      },
      {
        path: "contracts",
        name: "contracts",
        component: () =>
          import(/* webpackChunkName: "contracts" */ "../views/Contracts.vue"),
        meta: {
          requiresAuth: true,
          guest: true
        }
      },
      {
        path: "contract/:id",
        name: "contract",
        component: () =>
          import(/* webpackChunkName: "contract" */ "../views/Contract.vue"),
        meta: {
          guest: true,
          requiresAuth: true
        }
      },
      {
        path: "employeeneeds",
        name: "employeeneeds",
        component: () =>
          import(
            /* webpackChunkName: "employeeneeds" */ "../views/EmployeeNeeds.vue"
          ),
        meta: {
          guest: true,
          requiresAuth: true
        }
      },
      {
        path: "employeeneed/:id",
        name: "employeeneed",
        component: () =>
          import(
            /* webpackChunkName: "employeeneed" */ "../views/EmployeeNeed.vue"
          ),
        meta: {
          guest: true,
          requiresAuth: true
        }
      },
      {
        path: "today",
        name: "today",
        component: () =>
          import(/* webpackChunkName: "today" */ "../views/Today.vue"),
        meta: {
          guest: true,
          requiresAuth: true
        }
      },
      {
        path: "bonus_market",
        name: "bonus_market",
        component: () => import(/* webpackChunkName: "market" */ "../views/market/Market.vue"),
        meta: {
          guest: false,
          requiresAuth: true
        }
      },
      {
        path: "examination",
        name: "examination",
        component: () => import(/* webpackChunkName: "examination" */ "../views/examination/index.vue"),
        meta: {
          guest: false,
          requiresAuth: true
        }
      },

      {
        path: "examination/self",
        name: "examinationSelf",
        component: () => import(/* webpackChunkName: "ExaMyQuestionList" */ "../views/examination/ExaMyQuestionList.vue"),
        meta: {
          guest: false,
          requiresAuth: true
        }
      },

      {
        path: "examination/tasks",
        name: "examinationTasks",
        component: () => import(/* webpackChunkName: "ExaTaskList" */ "../views/examination/TaskList.vue"),
        meta: {
          guest: false,
          requiresAuth: true
        }
      },

      {
        path: "examination/question/:id/view",
        name: "examinationQuestionView",
        component: () => import(/* webpackChunkName: "ExaQuestionView" */ "../views/examination/QuestionEdit.vue"),
        meta: {
          guest: false,
          requiresAuth: true
        }
      }, 

      {
        path: "examination/task/:id/edit",
        name: "examinationTaskEdit",
        component: () => import(/* webpackChunkName: "ExaTaskEdit" */ "../views/examination/ExaTaskEdit.vue"),
        meta: {
          guest: false,
          requiresAuth: true
        }
      }, 

      
      {
        path: "examination/assigness",
        name: "examinationGroupsView",
        component: () => import(/* webpackChunkName: "ExaGroupsView" */ "../views/examination/ExaGroupsView.vue"),
        meta: {
          guest: false,
          requiresAuth: true
        }
      },
      
      {
        path: "examination/group/:id",
        name: "examination_group",
        component: () =>
          import(
            /* webpackChunkName: "examinationView" */ "../views/examination/ExaminationView.vue"
          ),
        meta: {
          guest: false,
          requiresAuth: true
        }
      },
      {
        path: "examination/sku/:id",
        name: "examination_sku",
        component: () =>
          import(
            /* webpackChunkName: "examinationSkuView" */ "../views/examination/ExaSkuView.vue"
          ),
        meta: {
          guest: false,
          requiresAuth: true
        }
      },
      {
        path: "examination/sku",
        name: "examination_sku_main",
        component: () =>
          import(
            /* webpackChunkName: "examinationSkuListView" */ "../views/examination/ExaSkuListView.vue"
          ),
        meta: {
          guest: false,
          requiresAuth: true
        }
      },
      {
        path: "examination/task/:sid/:id",
        name: "exa_task_view",
        component: () =>
          import(
            /* webpackChunkName: "examinationTaskView" */ "../views/examination/ExaTaskView.vue"
          ),
        meta: {
          guest: false,
          requiresAuth: true
        }
      },
      {
        path: "examination/runtime/task/:id",
        name: "exa_runtime_question_view",
        component: () =>
          import(
            /* webpackChunkName: "examinationaQuestionView" */ "../views/examination/ExaQuestionView.vue"
          ),
        meta: {
          guest: false,
          requiresAuth: true
        }
      },

      {
        path: "examination/runtime/:id/result",
        name: "exa_test_result",
        component: () =>
          import(
            /* webpackChunkName: "examinationaTestResult" */ "../views/examination/ExaTestResult.vue"
          ),
        meta: {
          guest: false,
          requiresAuth: true
        }
      },

      {
        path: "examination/runtime/:id/report",
        name: "exa_test_report_result",
        component: () =>
          import(
            /* webpackChunkName: "examinationaTestResult" */ "../views/examination/ExaTestReportResult.vue"
          ),
        meta: {
          guest: false,
          requiresAuth: true
        }
      },      

      {
        path: "examination/question",
        name: "exa_question_list_view",
        component: () =>
          import(
            /* webpackChunkName: "ExaQuestionList" */ "../views/examination/ExaQuestionList.vue"
          ),
        meta: {
          guest: false,
          requiresAuth: true
        }
      }
    ]
  },
  {
    // not found handler
    path: "*",
    component: () => import("../views/NotFound.vue")
  }
];

const router = new VueRouter({
  routes //,
  // надо разобраться с этим внизу после добавления VUEX
  //mode: 'history', // ???
  //linkExactActiveClass: 'active', // ???
  //scrollBehavior: function(to, from, savedPosition) { // у нас страницы длинные
  //  return savedPosition || { x: 0, y: 0 }
  //}
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (localStorage.getItem("jwt") == null) {
      console.log(
        to.fullPath + " require authorization! jwt is absent. Go to login page."
      );
      next({
        path: "/login",
        params: { nextUrl: to.fullPath }
      });
    } else {
      let user = JSON.parse(localStorage.getItem("user"));
      if (to.matched.some(record => record.meta.is_admin)) {
        if (user.is_admin == true) {
          console.log(to.fullPath + " asdfasdfa");
          next();
        } else {
          console.log(to.fullPath + " go home");
          next({ name: "home" });
        }
      } else {
        console.log(to.fullPath + " ok");
        next();
      }
    }
  } else if (to.matched.some(record => record.meta.guest)) {
    if (localStorage.getItem("jwt") == null) {
      next();
    } else {
      next({ name: "home" });
    }
  } else {
    next();
  }
});

export default router;
