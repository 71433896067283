<template>
  <v-app id="today">
    <!-- <v-navigation-drawer v-model="drawerRight" app clipped-right clipped right>
      <v-list dense>
        <v-list-item>
          <v-list-item-action>
            <v-icon>mdi-exit-to-app</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Open Temporary Drawer</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer> -->

    <v-navigation-drawer
      v-model="primaryDrawer.model"
      :clipped="primaryDrawer.clipped"
      :floating="primaryDrawer.floating"
      :mini-variant="primaryDrawer.mini"
      :permanent="primaryDrawer.type === 'permanent'"
      :temporary="primaryDrawer.type === 'temporary'"
      app
      overflow
    >
      <v-list
        dense
        nav
        class="py-0"
      >
        <!-- заголовок -->
        <v-list-item two-line>
          <v-list-item-avatar>
            <v-img
              :max-height="100"
              :max-width="100"
              :src="require('@/assets/'+config.view.logo.small)"
            />
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>{{ config.view.title }}</v-list-item-title>
            <v-list-item-subtitle>{{ config.view.subtitle }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-divider />

        <!-- основное меню -->
        <div v-if="isAdmin || isRegularUser">
          <v-list-item
            v-for="item in items.main"
            :key="item.title"
            :to="{ path: item.route }"
            link
            nav
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>

        <!-- <div v-if="isAdmin">
          <v-divider></v-divider>
          <v-subheader>Beta функции</v-subheader>
          <v-list-item
            v-for="item in betaItems"
            :to="{ path: item.route }"
            :key="item.title"
            link
            nav
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ item.title }}<sup>beta</sup></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div> -->

        <v-divider />

        <div v-if="betaOn || isAdmin">
          <v-divider />
          <v-subheader>Beta функции</v-subheader>
          <v-list-item
            v-for="item in items.beta"
            :key="item.title"
            :to="{ path: item.route }"
            link
            nav
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ item.title }}<sup>beta</sup></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar
      :clipped-left="primaryDrawer.clipped"
      app
      clipped-right
    >
      <v-app-bar-nav-icon
        v-if="primaryDrawer.type !== 'permanent'"
        @click.stop="primaryDrawer.model = !primaryDrawer.model"
      />
      <v-toolbar-title>{{ today() }}</v-toolbar-title>

      <v-spacer />

      <!-- <v-btn icon v-if="currentUser">
        <v-icon>mdi-export</v-icon>
      </v-btn> -->

      <!-- чтобы сейчас не убиваться... надо сначала сделать VUEX -->
      <!-- перенести в vuex как https://bezkoder.com/jwt-vue-vuex-authentication/ -->
      <!-- <div v-if="currentUser != null" class="navbar-nav ml-auto">
      <li class="nav-item" > 
          <router-link to="/profile" class="nav-link">
            <font-awesome-icon icon="user" />
            {{ currentUser.login }}
          </router-link>
        </li>
        <li class="nav-item">
        {{ currentUser.login }}
        <v-btn icon @click="logOut()">
          <a class="nav-link" href @click.prevent="logOut">
          <font-awesome-icon icon="sign-out-alt" />Выйти>
          <router-link to="/login" class="nav-link">
            <v-icon>mdi-export</v-icon>
          </router-link>
        </v-btn>
      </div> -->

      <v-btn
        icon
        @click="$router.push('/login')"
      >
        <v-icon>mdi-export</v-icon>
      </v-btn>
      <!-- <div v-if="currentUser == null" class="navbar-nav ml-auto">
        <v-icon onclick="">mdi-export</v-icon>
      </div> -->
    </v-app-bar>

    <v-content>
      <v-container fluid>
        <v-fade-transition mode="out-in">
          <router-view />
        </v-fade-transition>
      </v-container>
    </v-content>

    <v-footer
      :inset="footer.inset"
      app
    >
      <span class="px-4">{{ config.applicationName }}&copy; {{ new Date().getFullYear() }} v.{{
        cfg.version
      }}-{{ updated.updated !== "" ? updated.updated : "no-date" }}</span>
    </v-footer>
  </v-app>
</template>

<script>
import utils from "@/utils/utils";
//import config from "vue/src/core/config";
//import config from "@/../config.json"

export default {
  data: () => ({
    currentUser: null, //todo waiting for VUEX
    drawerRight: null,
    betaOn: false,
    isAdmin: false,
    isRegularUser: false, // todo waiting for role management
    items: {}
  }),
  mounted() {
    // attach menu items
    this.items = this.config.menu;
    // check if user is admin
    if (localStorage.getItem("user") != null) {
      let currentUser = JSON.parse(localStorage.getItem("user")); // перенести в vuex как https://bezkoder.com/jwt-vue-vuex-authentication/
      if (currentUser.is_admin == true) {
        this.betaOn = true;
        this.isAdmin = true;
      }
      if (currentUser.roles.includes("examination/teacher")) {
        // todo replace with role management
        this.isRegularUser = true;
      }
    }
  },
  methods: {
    today2: utils.today,
    today() {
      return utils.today();
    },
  },
};
</script>
