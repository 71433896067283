import axios, { AxiosResponse } from "axios";
import router from "@/router";
axios.interceptors.response.use(response => { return response; }, 
error => {
    console.error("interceptors", error, router);
    // todo, replace to route&state hook
    if (error.response.status === 401 || error.response.status === 403) {
        // fkung shit not working
        router.push({ name: 'login' })
        return Promise.reject(error);
    }
    return Promise.reject(error);
});

export function get<T = any>(url: string): Promise<AxiosResponse<T>> {
    if (url.startsWith('/'))
        url = `${API_URL}${url}`

    return axios.get<T>(url, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`
        }
    });
}

export function getWithHeader<T = any>(url: string, headers: any): Promise<AxiosResponse<T>> {
    if (url.startsWith('/'))
        url = `${API_URL}${url}`

    return axios.get<T>(url, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            ...headers
        }
    });
}

export function post<T = any>(url: string, data: any): Promise<AxiosResponse<T>>  {
    if (url.startsWith('/'))
        url = `${API_URL}${url}`
    return axios.post<T>(url, data, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`
        }
    });
}

export function postWithHeaders<T = any>(url: string, data: any, headers: any): Promise<AxiosResponse<T>>  {
    if (url.startsWith('/'))
        url = `${API_URL}${url}`
    return axios.post<T>(url, data, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            ...headers
        }
    });
}


export function patch<T = any>(url: string, data: any): Promise<AxiosResponse<T>>  {
    if (url.startsWith('/'))
        url = `${API_URL}${url}`
    return axios.patch<T>(url, data, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`
        }
    });
}

export function del<T = any>(url: string): Promise<AxiosResponse<T>>  {
    if (url.startsWith('/'))
        url = `${API_URL}${url}`
    return axios.delete<T>(url, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`
        }
    });
}
export const API_URL = process.env.VUE_APP_BASE_API_URL;