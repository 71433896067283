import Vue from 'vue'
import Vuex from 'vuex'
import { Module, createStore } from 'vuex-smart-module'
import { State, StateGetters, StateMutation } from './rootState';
import { marketModule } from "./marketState";
import { orderModule } from './orderStats';

Vue.use(Vuex);

const root = new Module({
    state: State,
    getters: StateGetters,
    mutations: StateMutation,
    modules: {
        marketModule,
        orderModule
    }
});


const store = createStore(root)

export { store }

export default root.context(store);