import { Getters, Mutations } from 'vuex-smart-module'

export type User = {
    _id: string;
    login: string;
    is_admin: boolean;
    fullname?: string;
    whatsapp?: string;
    telegram?: string;
    avatarUrl?: string;
}

export class Keys {
    public static SET_USER: string = "SET_USER"
    public static SET_TOKEN: string = "SET_TOKEN"
}

export class State {
    public user: User | null = null;
    public token: string | null = null;
}

export class StateMutation extends Mutations<State> {
    public SetUser(value: User | null) {
        this.state.user = value;
    }
    public SetToken(value: string | null) {
        this.state.token = value;
    }
}
export class StateGetters extends Getters<State> {
    get user(): User | null {
        return this.state.user;
    }
    get token(): string | null {
        return this.state.token;
    }
}
