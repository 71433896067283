import { get, del, patch, API_URL, post } from '@/api/axios';
import { Getters, Module, Mutations } from 'vuex-smart-module'

export type MarketItem = {
    _id?: string;
    description: string;
    imageUrl: string;
    price: number;
    title: string;
}

export class State {
    public items: MarketItem[] | null = null;

    public error: string | undefined;
}

export class StateMutation extends Mutations<State> {
    public async collectItems() {
        try {
            this.state.items = (await get<MarketItem[]>(`${API_URL}/api/@market/`)).data;
            console.log('Success fetch items from market module', this.state.items)
        } catch (e) {
            console.error(e);
            this.state.error = `${e}`; // damn
        }
    }

    public async saveItem(item: MarketItem) {
        await patch(`${API_URL}/api/@market/`, item);
    }

    public async deleteItem(item: MarketItem) {
        await del(`${API_URL}/api/@market/${item._id}`);
    }
}
export class StateGetters extends Getters<State> {
    get items(): MarketItem[] | null {
        return this.state.items;
    }

    getItemByID(id: string): MarketItem | null {
        if (!this.state.items) return null;
        return this.state.items.find((z) => z._id == id) ?? null;
    }
}


export const marketModule = new Module({
    state: State,
    getters: StateGetters,
    mutations: StateMutation
});