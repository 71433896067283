// utils to delay promise
/*function wait(ms) {
  return x => {
    return new Promise(resolve => setTimeout(() => resolve(x), ms));
  };
}*/

export default {
  today() {
    /*var months = [
      "январь",
      "февраль",
      "март",
      "апрель",
      "май",
      "июнь",
      "июль",
      "август",
      "сентябрь",
      "октябрь",
      "ноябрь",
      "декабрь"
    ];*/

    var months2 = [
      "января",
      "февраля",
      "марта",
      "апреля",
      "мая",
      "июня",
      "июля",
      "августа",
      "сентября",
      "октября",
      "ноября",
      "декабря"
    ];

    var weekDay = [
      "воскресенье",
      "понедельник",
      "вторник",
      "среда",
      "четверг",
      "пятница",
      "суббота"
    ];

    var date = new Date();
    var currentDate = "";
    if (typeof date.getDay() !== 'undefined') {
      currentDate += weekDay[date.getDay()] + ", ";
    }
    currentDate += 
      date.getDate() +
      " " +
      months2[date.getMonth()] +
      " " +
      (date.getFullYear() < 10 ? "0" : "") +
      date.getFullYear(); /*+
      ", " +
      (date.getHours() < 10 ? "0" : "") +
      date.getHours() +
      ":" +
      (date.getMinutes() < 10 ? "0" : "") +
      date.getMinutes() +
      ":" +
      (date.getSeconds() < 10 ? "0" : "") +
      date.getSeconds()*/

    return currentDate;
  }
};
