<template>
  <v-app id="start">
    <router-view />
  </v-app>
</template> 

<script>
export default {
	data: () => ({
		currentUser: null,
		section: "Head",
	}),
	mounted() {
		if (localStorage.getItem("user") != null) {
			this.currentUser = JSON.parse(localStorage.getItem("user")); // перенести в vuex как https://bezkoder.com/jwt-vue-vuex-authentication/
		}
	},
	methods: {
		logOut() {
			// this.$store.commit('SET_USER', null)
			// this.$store.commit('SET_TOKEN', null)
			// перенести в vuex как https://bezkoder.com/jwt-vue-vuex-authentication/
			localStorage.removeItem("user");
			localStorage.removeItem("jwt");
			this.currentUser = null;
			this.$router.push("/login");
		},
	},
};
</script>
