import Vue from "vue";
import App from "./App.vue";
import PortalVue from "portal-vue";
import router from "./router";
import config from "./config.js"; // зачем тогда config.json?
import configuration from "../config.json";
import updated from "./../updated.json"; // updated.json - генерируется в deploy.sh и содержит дату сборки
import Axios from "axios";
import vuetify from "./plugins/vuetify";
import { store } from "./state";

//import build from './utils/generate-build'

// add axios
Vue.prototype.$http = Axios; // for this.$http...

// ???
Vue.config.productionTip = false; 

// attach portal vue component
Vue.use(PortalVue);

// global variable to customize screen 
Vue.mixin({
  // ????
  data: () => ({
    cfg: config.data(),
    config: configuration,
    updated: updated,
    drawers: ["permanent", "temporary"],
    user: null,
    primaryDrawer: {
      model: null,
      type: "default (no property)",
      clipped: false,
      floating: false,
      mini: false
    },
    footer: {
      inset: false
    }
  }),
  created: function() {
    if (localStorage.getItem("user") != null) {
      (this as any).user = JSON.parse(localStorage.getItem("user")!); // todo перенести в vuex как https://bezkoder.com/jwt-vue-vuex-authentication/ ИЛИ в mixijn
    }
  }
});

// TODO Check local storage to handle refreshes
// if (window.localStorage) {
//   var localUserString = window.localStorage.getItem('user') || 'null'
//   var localUser = JSON.parse(localUserString)

//   if (localUser && store.state.user !== localUser) {
//     store.commit('SET_USER', localUser)
//     store.commit('SET_TOKEN', window.localStorage.getItem('token'))
//   }
// }

const app: Vue = new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
});

app.$mount("#app");
